import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import ScrollableSection from "../components/scrollable-section"
import Twitter from "../assets/svg/twitter.svg"
import Facebook from "../assets/svg/facebook.svg"
import Arrow from "../assets/svg/arrow-right.svg"
import ViewAll from "../assets/svg/grid.svg"
import ProjectSlider from "../components/projectSlider"

const SINGLE_PROJECT_BG_QUERY = graphql`
    query SingleProjectBgQuery {
        placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const SingleProject = props => {
    const {
        pageContext: {
            id,
            projectId,
            title,
            excerpt,
            content,
            uri,
            prev,
            next,
            featuredImage,
            projectFields,
        },
    } = props
    const { placeholderImage } = useStaticQuery(SINGLE_PROJECT_BG_QUERY)

    const images = projectFields.gallery
        ? [featuredImage, ...projectFields.gallery]
        : [featuredImage]

    console.log(images)

    // const images = [featuredImage, ...projectFields.gallery]

    return (
        <>
            <SEO
                title={title}
                description={excerpt}
                image={featuredImage.sourceUrl}
            />
            <article
                data-id={id}
                id={`post-${projectId}`}
                className={`post-${projectId} post post--single`}
            >
                <ScrollableSection classNames="post__hero">
                    <Img
                        fluid={placeholderImage.childImageSharp.fluid}
                        style={{
                            position: `absolute`,
                            top: 0,
                            left: 0,
                            width: `100%`,
                            height: `100%`,
                            opacity: `0.06`,
                            zIndex: -1,
                        }}
                    />
                    <h1>{title}</h1>
                </ScrollableSection>
                <ScrollableSection classNames="post__wrapper">
                    <ProjectSlider images={images} />
                    <div className="post__content-wrapper">
                        <div
                            className="post__content"
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                        <div className="post__share">
                            share
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`https://twitter.com/share?url=omahabydesign.org/${uri}`}
                            >
                                <Twitter />
                            </a>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`https://www.facebook.com/sharer/sharer.php?u=&omahabydesign.org/${uri}`}
                            >
                                <Facebook />
                            </a>
                        </div>
                    </div>
                </ScrollableSection>
            </article>

            <nav className="navigation post-navigation" role="navigation">
                <h2 className="screen-reader-text">Post navigation</h2>
                <div className="post-navigation__links">
                    <div>
                        {prev && (
                            <TransitionLink
                                to={`/${prev.slug}`}
                                rel="prev"
                                className="post-navigation__link post-navigation__link--prev"
                                exit={{
                                    trigger: ({ exit, node }) => {
                                        window.scrollTo({
                                            left: 0,
                                            top: 0,
                                        })
                                        TweenMax.to(node, 0.9, {
                                            autoAlpha: 0,
                                        })
                                    },
                                    length: 1,
                                    zIndex: 2,
                                }}
                                entry={{
                                    trigger: ({ entry, node }) => {
                                        TweenMax.fromTo(
                                            node,
                                            0.9,
                                            {
                                                autoAlpha: 0,
                                            },
                                            { autoAlpha: 1, delay: 0.9 }
                                        )
                                    },
                                    delay: 1,
                                    zIndex: 0,
                                }}
                            >
                                <span className="meta-nav" aria-hidden="true">
                                    Prev
                                </span>
                                <span className="screen-reader-text">
                                    Previous post:
                                </span>{" "}
                                <br />
                                <span className="svg">
                                    <Arrow />
                                </span>
                            </TransitionLink>
                        )}
                    </div>

                    <div className="post-navigation__all">
                        <TransitionLink
                            to={`/areas-of-impact`}
                            className="post-navigation__link post-navigation__link--all"
                            exit={{
                                trigger: ({ exit, node }) => {
                                    window.scrollTo({
                                        left: 0,
                                        top: 0,
                                    })
                                    TweenMax.to(node, 0.9, {
                                        autoAlpha: 0,
                                    })
                                },
                                length: 1,
                                zIndex: 2,
                            }}
                            entry={{
                                trigger: ({ entry, node }) => {
                                    TweenMax.fromTo(
                                        node,
                                        0.9,
                                        {
                                            autoAlpha: 0,
                                        },
                                        { autoAlpha: 1, delay: 0.9 }
                                    )
                                },
                                delay: 1,
                                zIndex: 0,
                            }}
                        >
                            <span className="meta-nav">view all</span>
                            <ViewAll />
                        </TransitionLink>
                    </div>

                    <div>
                        {next && (
                            <TransitionLink
                                to={`/${next.slug}`}
                                rel="next"
                                className="post-navigation__link post-navigation__link--next"
                                exit={{
                                    trigger: ({ exit, node }) => {
                                        window.scrollTo({
                                            left: 0,
                                            top: 0,
                                        })
                                        TweenMax.to(node, 0.9, {
                                            autoAlpha: 0,
                                        })
                                    },
                                    length: 1,
                                    zIndex: 2,
                                }}
                                entry={{
                                    trigger: ({ entry, node }) => {
                                        TweenMax.fromTo(
                                            node,
                                            0.9,
                                            {
                                                autoAlpha: 0,
                                            },
                                            { autoAlpha: 1, delay: 0.9 }
                                        )
                                    },
                                    delay: 1,
                                    zIndex: 0,
                                }}
                            >
                                <span className="meta-nav" aria-hidden="true">
                                    Next
                                </span>
                                <span className="screen-reader-text">
                                    Next post:
                                </span>{" "}
                                <br />
                                <span className="svg">
                                    <Arrow />
                                </span>
                            </TransitionLink>
                        )}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default SingleProject
