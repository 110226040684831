import React, { Component } from "react"
import NumberedTabs from "../components/numbered-tabs"

class ProjectSlider extends Component {
    state = { current: 0 }

    handlePreviousClick = () => {
        const previous = this.state.current - 1

        this.setState({
            current: previous < 0 ? this.props.images.length - 1 : previous,
        })
    }

    handleNextClick = () => {
        const next = this.state.current + 1

        this.setState({
            current: next === this.props.images.length ? 0 : next,
        })
    }

    render() {
        const { current } = this.state
        const { images } = this.props
        const wrapperTransform = {
            transform: `translateX(-${current * (100 / images.length)}%)`,
        }

        return (
            <section className="project-slider">
                <div
                    className="project-slider__wrapper"
                    style={wrapperTransform}
                >
                    {images.map(image => (
                        <figure
                            key={image.id}
                            className="project-image"
                            current={current}
                        >
                            {image.node && (
                                <img
                                    src={image.node.sourceUrl}
                                    srcSet={image.node.srcSet}
                                    alt=""
                                />
                            )}
                            {!image.node && (
                                <img
                                    src={image.sourceUrl}
                                    srcSet={image.srcSet}
                                    alt=""
                                />
                            )}
                        </figure>
                    ))}
                </div>
                {images.length > 1 && (
                    <div className="project-slider__controls">
                        <NumberedTabs
                            current={current + 1}
                            length={images.length}
                            next={this.handleNextClick}
                            prev={this.handlePreviousClick}
                        />
                    </div>
                )}
            </section>
        )
    }
}

export default ProjectSlider
